html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.App{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}
h1{
  text-align: center;
}

#container {
  display: flex;
  width: 100%;
}

#editor-container{
  /* overflow: auto; */
  resize: horizontal;
  overflow-x: auto;
  overflow-y: hidden;
}

#editor{
  width: 100%;
  height:100%;
  overflow: auto;
}
.divider {
  width: 4px;
  cursor: col-resize;
  background-color: #aaa;
}

#preview-container {
  overflow: auto;
  border: 1px solid black;
}

#preview{
  width: 100%;
  height: 100%;
  border: 1px solid black;
}
#footer{
  margin-top: 10px;
  text-align: center;
}